import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/prices-dermamina.jpg";
import Newarrow from '../../assets/image/jpeg/enter.svg';
import Fatareas from '../../assets/image/jpeg/fat-areas.jpg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const FatContentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row className="pb-4">
              <Col  data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="300">
                <Iwrap>

<Itext as="h2">Fat Dissolving <span>UK</span></Itext>
</Iwrap>
      <SecondText>
      What is fat dissolving treatment?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4  mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="300"
              data-aos-once="false"
            >
              <img src={imgMobile} alt="What is Fat Dissolving" className="rounded shadow" />
            </div>
          </Col>
          <Col data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            data-aos-delay="300" lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

              Aqualyx is a type of fat dissolving treatment that targets localised areas of stubborn fat. A natural substance called deoxycholic, which naturally occurs in the body is injected directly into the area of excess body fat helping to break down and eliminate fat . <br /> <br />

Common areas treated include the chin, around the waistline, stomach, hips and thighs. The areas to treat are injected with a fine needle straight into the fat . This causes the walls of fat  to break down then be eliminated by the body’s natural metabolic processes.<br /> <br />






              </Text>
            
              <a href="https://connect.pabau.com/bookings.php?compid=8607" activeClassName="active"><Bookbutton>Book Appointment
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>
      

        

  
      </Container>
    </Section>
  </>
);

export default FatContentPage;
