import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function Fatfaq() {
    return (
        <Section py={4} id="faq" bg="#fff" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5 pt-3 pb-">
        <Row className="justify-content-center pb-5">
          <Col   data-aos="fade-up"
             data-aos-duration="750"
             data-aos-once="false"
             data-aos-delay="300" lg="12">
   
            <Iwrap>


<Itext as="h2">More about <span>Fat Dissolving</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row   data-aos="fade-up"
             data-aos-duration="750"
             data-aos-once="false"
             data-aos-delay="300" className="">
        <Col
     
     >
           <Accordion allowZeroExpanded>
<AccordionItem className='blanking'>
    <AccordionItemHeading>
        <AccordionItemButton>
        How many fat dissolving sessions will I need?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The number of fat dissolving sessions required will vary depending on several factors, including the amount of fat to be treated, the area being targeted, individual metabolism, lifestyle, and desired results. Generally, multiple sessions are required to achieve optimal results.


        </p>
    </AccordionItemPanel>
</AccordionItem> 




</Accordion>    <br />
         


           <Accordion allowZeroExpanded className="">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is fat dissolving painful?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Fat dissolving is regarded as a non-invasive procedure. Pain experienced during Aqualyx can vary from person to person. The majority only experience a little discomfort throughout the treatment as a fine needle is used. 


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded className="">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is Aqualyx fat dissolving London safe?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
   
        Aqualyx is often regarded as safe in the hands of a trained and knowledgeable provider. Deoxycholic acid, an organic compound present in the body that aids in the breakdown and elimination of fat , is the active component of Aqualyx. This fat dissolving substances have been extensively studied and has been approved for use since 2009.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded className="">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What is used for fat dissolving?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We use the leading Aqualyx for fat dissolving.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded className="">
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can Men have fat dissolving?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Most certainly. It is a common treatment for men especially in helping with reducing stomach fat and could help attain a six pack figure/ six pack abs.
 
      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How should I prepare for Aqualyx London fat dissolving treatment?   </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        -	Area Is advised to be clean and makeup free <br />
-	Shaving is recommended but not a necessity  <br />
-	Forms all completed as all pre treatment care must be followed <br />

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
<br /> 


        

<Accordion allowZeroExpanded className="">
  
          <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is this fat loss treatment at home London be possible?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Unfortunately not as it must be administered by a trained and knowledgeable specialist.

        </p> 
    </AccordionItemPanel>
</AccordionItem>

</Accordion>

<br /> 
<Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I go on a flight after fat dissolving?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We advise leaving 48 hours post treatment before going on a flight.
        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  

<br /> 
<Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How effective is this fat loss treatment London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        When Aqualyx is injected into fatty areas, the fat  are broken down, allowing you to lose inches from your frame. It is known to be a leading and effective treatment especially for the chin area and as a fat dissolving treatment for stomach and treatment for six packs/ six pack abs.        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  




<br /> 
<Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What age must be you be to get the fat loss treatment London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patient must be 18 or above to have any treatments at Dermamina London.      </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion> 


<br /> 
<Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is a consultation provided on the day of the treatment?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes. At Dermamina London, pre-treatment consultations are crucial in ensuring that patients are well-prepared and at ease in the hands of their therapists, as well as, most significantly to ensure the patient is fully informed about the treatment and also understand what the patient is looking to achieve.
             </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion> 


<br /> 
<Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What to expect during fat loss treatment London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        A fine needle is used during the procedure to inject the Aqualyx right into the troublesome fat. During the injection, some patients may feel a minor stinging or burning sensation, but this is often brief and mild. Some individuals may suffer minor swelling, redness, or bruising in the treated area after the injection, although these symptoms are expected and usually go away within a few days.
             </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion> 


          </Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}